import React from "react";
import ICasino from "../../components/ICasino/ICasino";

function page() {
  return (
   
        <ICasino />
     
  );
}

export default page;