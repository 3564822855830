import React from "react";
import MainGame from '../../components/MainGame/MainGame'


function Page() {

  return (
    <div>
      <MainGame />
     
    </div>
  );
}

export default Page;
