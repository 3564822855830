import React from 'react'
import Profile from '../../components/Profile/Profile'

function page() {
  return (
    
    <Profile />
  
       
  )
}

export default page